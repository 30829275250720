import "./Loader.css"
const Loader = () => {
  return (
<div className="loader">
  <div className="circle" />
  <div className="circle" />
  <div className="circle" />
  <div className="circle" />
</div>
  )
}

export default Loader
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router";

export default function CustomSearchInputFeild({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  submitClick,
  loading=false
}) {
  const navigate = useNavigate();
  const handleClick = () => {
     
     const temp =new Date(startDate).toLocaleDateString();
     
    navigate(`?start=${startDate}&end=${endDate}`);
    submitClick()
  };
  return (
    <div
    className="flex items-center flex-wrap justify-center"
     
    >
      
      <div
   

        style={{

          flexDirection: "column",
          display: "flex",
        }}
      >
        <label>Start Date</label>

        <DatePicker
          selectsStart
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          startDate={startDate}
        />
      </div>

      <div
        style={{
          marginLeft: "10px",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <label>End Date</label>
        <DatePicker
          selectsEnd
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          endDate={endDate}
          startDate={startDate}
          minDate={startDate}
        />
      </div>

     {loading?
    
      <button
      
      type="button"
        className="px-3 min-w-[69px] flex items-center justify-center py-2 grow md:grow-0 rounded-lg bg-black text-white font-bold mt-6 ml-4"
      >
                 <span>
              <svg
                className="animate-spin"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.5"
                  cx="10"
                  cy="10"
                  r="9"
                  stroke="white"
                  stroke-width="2"
                />
                <mask id="path-2-inside-1_2527_20936" fill="white">
                  <path d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z" />
                </mask>
                <path
                  d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z"
                  stroke="white"
                  stroke-width="4"
                  mask="url(#path-2-inside-1_2527_20936)"
                />
              </svg>
            </span>
      </button>
     
     : <button
        className="px-3 py-2 grow md:grow-0 rounded-lg bg-black text-white font-bold mb-1 mt-auto ml-4"
        onClick={handleClick}
      >
        Submit
      </button>}
    </div>
    // <div style={{}}>

    //     <input value={value} placeholder='Search By Name or Number' type='date' className='outline-none px-4 w-[250px]'
    //     onChange={onchange}
    //     />
    // </div>
  );
}
